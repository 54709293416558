import { useState, useContext, useCallback, useEffect } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import { ReactComponent as Opensea } from "./opensea.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import { ReactComponent as Etherscan } from "./etherscan.svg";
import { ReactComponent as X2Y2 } from "./x2y2.svg";
import { ReactComponent as Look } from "./looks.svg";
import "bootstrap/dist/css/bootstrap.css";

function App() {
  const [count, setCount] = useState(2);
  const [mintedCount, setMintedCount] = useState(0);
  const {
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count < 10) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0) {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, mint]);

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);

  useEffect(() => {
    connectBrowserWallet();
  }, []);

  return (
    <>
      <header className="header-container socials d-flex align-items-center">
        <a target="_blank" rel="noreferrer" href="https://opensea.io/collection/the-sazukis" className="social-btn">
          <Opensea />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/TheSazukis"
          className="social-btn"
        >
          <Twitter />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://x2y2.io/collection/the-sazukis/"
          className="social-btn"
        >
          <X2Y2 />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://looksrare.org/collections/0xeCf1E59a3e3C5327232776B3d1E98582396e3F32"
          className="social-btn"
        >
          <Look />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://etherscan.io/address/0xecf1e59a3e3c5327232776b3d1e98582396e3f32"
          className="social-btn"
        >
          <Etherscan />
        </a>
      </header>
      <section className="banner-container">
        <div className="container banner-section">
          <div className="row banner-row">
            <div className="col-12">
              <button
                onClick={connectBrowserWallet}
                type="button"
                className="btn btn-primary btn-connect mb-5"
              >
                {!userData ? "Connect Wallet" : userData.account}
              </button>
              <h1>THE SAZUKIS</h1>
              <p>5.555 FreeMint Sazukis to save your rekt bags</p>
              <h4>
                <b>#MAXBIDDING</b>
              </h4>

              <div className="mint-container">
                <h2 className="text-uppercase">
                  <b>THE SAZUKIS DROP</b>
                </h2>
                <p>FreeMint, additional Sazuki 0.0039</p>
                <div className="sazuki-form-counter">
                  <button
                    onClick={handleCounter}
                    type="button"
                    className="sazuki-btn"
                    data-pos="minus"
                  >
                    -
                  </button>
                  <span> {count} </span>
                  <button
                    onClick={handleCounter}
                    type="button"
                    className="sazuki-btn"
                    data-pos="plus"
                  >
                    +
                  </button>
                  <button
                    onClick={handleCustomMint}
                    type="button"
                    className="claim-btn"
                  >
                    Mint
                  </button>
                </div>
                {!!contractDetails && (
                  <h3 className="my-3">{mintedCount} / 5555</h3>
                )}
                {transactionHash && (
                  <a
                    href={`https://etherscan.io/tx/${transactionHash}`}
                    target="_blank"
                    rel="noreferrer"
                    className="claim-btn d-flex align-items-center"
                  >
                    See transaction
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
